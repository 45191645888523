.constructor-toolbar {
  z-index: 999;
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: calc(90% - 16.625rem);
  max-width: 1560px;
  box-sizing: content-box;
  padding: 8px 16px;

  background: rgb(116, 129, 148);
  border-radius: 6px 6px 0 0;
  box-shadow: 0 24px 40px rgba(65, 69, 88, 0.1);

  justify-content: space-between;

  h6 {
    color: white;
  }
  .toolbar-items.columns_toolbar {
    margin: 0 0 0 15px;
  }
  .toolbar-items {
    list-style: none;
    padding: 0;
    margin: 0 15px 0;

    li {
      cursor: pointer;
      border-radius: 4px;
      margin: 0 4px;
      height: 36px;
      width: 36px;
      background: rgb(243, 243, 243);

      * {
        pointer-events: none;
      }
    }
  }
  .toolbar-items.static {
    li {
      cursor: default;
    }
  }
  .constructor-toolbar-actions {
    margin-left: 16px;
    white-space: nowrap;

    button {
      padding: 6px 16px;
      font-weight: 400;
      font-size: 16px;
      color: white;
      border: none;
      box-shadow: none;
      white-space: nowrap;
      margin: 0 4px;
      cursor: pointer;

      &.save-btn {
        background: rgb(26, 29, 31);
        color: white;
      }

      &.preview-btn {
        background: transparent;

        img {
          margin-right: 5px;
        }
      }
    }
  }

  .toolbar-draggable-item-copy ~ .toolbar-draggable-item-inputs,
  .toolbar-draggable-item-copy ~ .toolbar-draggable-item-columns {
    transform: none !important;
  }

  //#toolbar-droppable-body
  div[data-rbd-placeholder-context-id] {
    display: none !important;
  }
}

.constructor-toolbar {
  //margin-left: -1.5rem;
  left: 15.625rem;
  width: calc(80% - 15.625rem);
}

.navbar-vertical-collapsed {
  .constructor-toolbar {
    left: 3.125rem;
    width: calc(80% - 3.125rem);
  }
}

.navbar-vertical-not-collapsed {
  .constructor-toolbar {
    @media screen and (max-width: 1200px) {
      left: 3.125rem;
      width: calc(80% - 3.125rem);
    }
  }
}
