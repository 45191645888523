@import "react-grid-overrides";
#sellbyx_constructor *:not(option) {
  font-family: Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-weight: 500;
}
#sellbyx_constructor.form_card_constructor .card_constructor {
  background: transparent;
}
#sellbyx_constructor.client_card .context-menu-content {
  padding: 5px 10px;
  width: 100px;
}
#sellbyx_constructor.form_card_constructor {
  border-radius: 5px;
}
#sellbyx_constructor {
  input, textarea, select {
    outline: none;
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: initial;
    box-shadow: none;
    border: 0 solid rgba(0,0,0,0.125);
    border-radius: 0.375rem;
  }
  .card_constructor .card-body {
    display: block;
    overflow: visible;
  }
  .card-body:last-child {
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }
  .card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
  }
  .card-header:first-child {
    border-radius: 0.375rem 0.375rem 0 0;
  }
  .card-header {
    padding: 1rem 1.25rem;
    margin-bottom: 0;
    background-color: transparent;
    border-bottom: 0 solid rgba(0,0,0,0.125);
  }
  .card_constructor {
    height: 100%;
  }
  .section-title {
    display: flex;
    margin: 0;
    font-size: 1.2rem;
    color: #0b1828;
    align-items: center;
  }
  .layoutJSON {
    background: #ddd;
    border: 1px solid black;
    margin-top: 10px;
    padding: 10px;
  }
  .columns {
    -moz-columns: 120px;
    -webkit-columns: 120px;
    columns: 120px;
  }
  .title_drag_element {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: 0;
    border-radius: 0;
  }
  .toolbox {
    background-color: #dfd;
    width: 100%;
    height: 120px;
    overflow: scroll;
  }
  .form-label {
    margin-bottom: 0.5rem;
    font-size: .875rem;
    font-weight: 500;
    letter-spacing: .02em;
    display: inline-block;
  }
  .hide-button {
    cursor: pointer;
    position: absolute;
    font-size: 20px;
    top: 0px;
    right: 5px;
  }
  .constructor_field {
    margin-bottom: 12px;
  }
  .toolbox__title {
    font-size: 24px;
    margin-bottom: 5px;
  }
  .toolbox__items {
    display: block;
  }
  .toolbox__items__item {
    display: inline-block;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    padding: 10px;
    margin: 5px;
    border: 1px solid black;
    background-color: #ddd;
  }
  .droppable-element {
    width: 150px;
    text-align: center;
    background: #fdd;
    border: 1px solid black;
    margin: 10px 0;
    padding: 10px;
  }
  .draggableHandle {
    cursor: move;
  }
  .draggableForm {
    cursor: grab;
  }
  .form-control {
    display: block;
    width: 100%;
    padding: 0.3125rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #344050;
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid #d8e2ef;
    appearance: none;
    border-radius: 0.25rem;
    box-shadow: none;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
  .form-control[readonly] {
    background-color: #edf2f9;
    opacity: 1;
  }
  .field-columns {
    flex: 1;
    display: flex;
    margin: 0;
    & > .padding {
      padding-right: 1rem !important;
    }

    & > .padding:last-child {
      padding-right: 0 !important;
    }
  }
  .calendar_select>div:nth-child(3):hover {
    border: 1px solid #d8e2ef;
  }
  .calendar_select > div:nth-child(4n) {
    box-shadow: 0 2px 8px 0 #737577;
  }
  .calendar_select *{
    font-family: Arial, sans-serif;
    color: #344050;
    box-shadow: none;
  }
  .calendar_select {
    cursor: pointer;
  }
  .checkbox_parent,
  .radio_parent {
    padding: 6px 15px;
    border: 1px solid rgb(216, 226, 238);
    border-radius: 4px;
    min-height: 36px;
  }
  .form-check-label {
    font-size: 16px;
    color: #748194;
    font-weight: 400!important;
    line-height: 1.45rem;
    margin: 0;
  }
  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: .5;
  }
  .form-check-input:checked {
    background-color: #2a85ff;
    border-color: #2a85ff;
  }
  .form-check-input {
    width: 1em;
    height: 1em;
    margin-top: 0.25em;
    vertical-align: top;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    border: 1px solid #b6c1d2;
    appearance: none;
  }
  .form-check-input[type='checkbox'] {
    border-radius: 0.25em;
    margin-right: 0.5rem;
  }
  .form-check-input[type='radio'] {
    border-radius: 50%;
    margin-right: 0.5rem;
  }
  .form-check-input, .form-select {
    appearance: none;
    -webkit-appearance: none;
  }
  .form-check-input:checked[type=radio] {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='2' fill='%23fff'/%3E%3C/svg%3E");
  }
  .form-check-input:checked[type=checkbox] {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3 6-6'/%3E%3C/svg%3E");
  }
  select {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%234d5969' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: calc(100% - 15px), 55%;
  }
  .constructor_form_submit {
    padding: 7px 15px;
    border: none;
    outline: none;
    margin-right: 17px;
    margin-bottom: 15px;
    margin-top: 15px;
    color: white;
    background: #2a85ff;
    border-radius: 4px;
    font-weight: 500;
  }
  .fields-action-bar {
    position: absolute;
    top: 36px;
    z-index: 100;
    right: 1px;
    button {
      border: 1px solid rgb(216, 226, 238);
      background: white;
      font-size: 0;
      width: 24px;
      height: 24px;
      margin: 0 5px;
      box-shadow: none;
      transition: 0.3s;

      &:hover img {
        filter: grayscale(100%) brightness(62%) sepia(100%) hue-rotate(-50deg)
        saturate(600%) contrast(0.8);
        transition: 0.3s;
      }
    }
  }
  .editable.draggable-block {
    position: relative;
    display: flex;
    flex-direction: row;
  }
  .drag-burger {
    width: 20px;
    line-height: 10px;
    margin-top: 3px;
    cursor: grab;
  }
  .field-wrapper-element {
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    width: 100%;
  }
  .control-wrapper {
    flex: 1;
  }
  .section-menu {
    &-btn {
      border: 1px solid transparent;
      background: transparent !important;
      font-size: 0;
      width: 20px;
      height: 20px;
      margin-left: 8px;
      position: relative;
      box-shadow: none;
      &:hover,
      &:active {
        border: 1px solid #d8e2ee;
      }

      &:before {
        content: "...";
        color: rgb(26, 29, 31);
        top: 31%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 24px;
        position: absolute;
      }
    }
    &-content {
      background: white;
      padding: 27px 29px;
      font-size: 13px;
      color: rgb(26, 29, 31);
      border-radius: 4px;
      position: absolute;
      top: 40px;
      font-weight: 500;
      left: -20px;
      width: 146px;
      box-shadow: 0px 12px 36px rgba(0, 0, 0, 0.12);
      z-index: 2;
      div {
        cursor: pointer;
      }
    }
  }
  .is-dragged-over {
    background-color: rgba(255, 0, 0, 0.05);
    border: 1px dotted rgba(255, 0, 0, 1);
  }

  .section-sortable.sortable-container {
    min-height: 100%;
  }

  .long_first .c-column:first-child {
    flex: 2;
  }
  .short_first .c-column:nth-child(2) {
    flex: 2;
  }
  .short_first .c-column:first-child {
    flex: 1;
  }

  .c-column {
    min-height: 40px;
    flex: 1;
  }

  .c-column .sortable-container {
    display: flex;
    height: 100%;
  }

  .sortable-container {
    display: flex;
  }

  .sortable-container.horizontal {
    flex-direction: row;
  }
  .sortable-container.vertical {
    flex-direction: column;
  }

  .c-column .sortable-container:empty {
    border: 1px solid rgb(194, 171, 171);
  }

  .sortable-placeholder {
    opacity: 0.5;
    border: 1px dashed green;
    pointer-events: none;
  }

  .sortable-none {
    display: none
  }

  .sortable-drag {
    opacity: 0.5;
    width: 218px!important;
    height: 60px!important;
    overflow: hidden;
    position: relative;
    border: 1px dashed red;
    pointer-events: none;
  }
  .sortable-drag:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 1;
    top: 0;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAANoAAAA8CAYAAAAAAKREAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAFJSURBVHhe7d0xSkRBFERR958buBEXIO5A3IKBqZkITwom0rR/MQ/OgU6MOqj7nawfBric0KBAaFAgNCgQGhQIDQqEBgVCgwKhQYHQoEBoUCA0KBAaFBwP7fNr5vl95ul15vHFcXac7DW7zX6vcDS0XDIXfvuY+f65/REWyF6z2+z3itiOhpYvQi4LW2W/2fFpR0PL18B/MjbLfrPj046Glt+6sN0VOxYa/CE0KBAaFAgNCoQGBUKDAqFBgdCgQGhQIDQoEBoUCA0KhAYFQoMCoUGB0KBAaFAgNCgQGhQIDQqEBgVCgwKhQYHQoEBoUCA0KBAaFNx9aB65YLsVj1x4tontVjzb5CFCtspes9vs9+4fIoxcMl+EXDi/dR1nw8les9srIovjoQH/CQ0KhAYFQoMCoUGB0KBAaFAgNCgQGhQIDQqEBpeb+QWaNY1B3DO40gAAAABJRU5ErkJggg==");
  }
  //custom-select css

  .custom_form_select {
    border: 1px solid #d8e2ef;
    min-height: 36px;
    display: flex;
    align-items: center;
    padding: 0 15px 0 10px;
    border-radius: 4px;
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: calc(100% - 15px), 55%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%234d5969' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E");
  }
  .custom_form_select.read_only {
    background: #edf2f9;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .options_list {
    box-shadow: 0 12px 36px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    z-index: 999;
    position: absolute;
    background: white;
    width: 100%;
    display: none;
  }
  .options_list.open {
    max-height: 280px;
    overflow-y: scroll;
    box-shadow: none;
    display: block;
  }
  .options_list.top {
    top: -280px;
  }
  .options_list li {
    cursor: pointer;
    padding: 5px 10px;
    border-bottom: 1px solid #d8e2ef;
  }
  .options_list li:last-child {
    border: none;
  }
  .select_container {
    position: relative;
  }
  .custom_form_select input {
    outline: none;
    border: none;
    background: transparent;
    flex-grow: 1;
    z-index: 1;
    position: relative;
  }
  .input_parent {
    display: flex;
    width: 100%;
    position: relative;
  }
  .single_select_value {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    padding-left: 2px;
  }
  .hidden_select {
    width: 0;
    height: 0;
    position: absolute;
    opacity: 0;
    left: -9999px;
  }
  .multiple_element {
    background: rgba(230, 230, 230);
    padding: 0 0 0 6px;
    margin: 5px 2px 0px 2px;
    position: relative;
    display: flex;
    align-items: center;
    z-index: 1;
    justify-content: space-between;
    width: 93%;
  }
  .close_span {
    width: 25px;
    margin-left: 10px;
    display: inline-block;
    cursor: pointer;
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 100%;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAAE5QTFRFAAAAIx8gIx8gIx8gIx8gIx8gIx8gIx8gIx8gIx8gIx8gIx8gIx8gIx8gIx8gIx8gIx8gIx8gIx8gIh8fIx4hIx8gIx8gIx8gIx8g////qFhM1wAAABh0Uk5TAAAKGAMJl9prBJLX8m4B83D3+AEB1giNxISErAAAAAFiS0dEGexutYgAAAAJcEhZcwABOvYAATr2ATqxVzoAAACHSURBVDjL5ZJJDoAgDACtVAXccEP+/1KDook1tXflBJ1JupFlvzqQK4TrgaoAwouy0iYFwejKEgNUHZr2MMC0TagVETrdJ2Pnve5ojhiOxnV5VHkA5PhpOMfxZAwDy2P7bgxhdMhwUZBSSEWebeL7GNhBwURHPdNlLcKyvL2ve/U0h/Bhvn42thUKKJlqzs0AAAAldEVYdGRhdGU6Y3JlYXRlADIwMTgtMTEtMDlUMTA6MDM6MjIrMDE6MDC3+udLAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDE4LTExLTA5VDEwOjAzOjIyKzAxOjAwxqdf9wAAAEZ0RVh0c29mdHdhcmUASW1hZ2VNYWdpY2sgNi43LjgtOSAyMDE2LTA2LTE2IFExNiBodHRwOi8vd3d3LmltYWdlbWFnaWNrLm9yZ+a/NLYAAAAYdEVYdFRodW1iOjpEb2N1bWVudDo6UGFnZXMAMaf/uy8AAAAYdEVYdFRodW1iOjpJbWFnZTo6aGVpZ2h0ADUxMsDQUFEAAAAXdEVYdFRodW1iOjpJbWFnZTo6V2lkdGgANTEyHHwD3AAAABl0RVh0VGh1bWI6Ok1pbWV0eXBlAGltYWdlL3BuZz+yVk4AAAAXdEVYdFRodW1iOjpNVGltZQAxNTQxNzU0MjAyrrLbNQAAABN0RVh0VGh1bWI6OlNpemUAOS4zNEtCQo9MI1EAAAA8dEVYdFRodW1iOjpVUkkAZmlsZTovLy4vdXBsb2Fkcy81Ni91dm5kc1MyLzE2NzQvY2xvc2VfMTExMTUyLnBuZzVJD1EAAAAASUVORK5CYII=');
  }
  .close_span:hover {
    background: #DDAB8D;
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAAE5QTFRFAAAAIx8gIx8gIx8gIx8gIx8gIx8gIx8gIx8gIx8gIx8gIx8gIx8gIx8gIx8gIx8gIx8gIx8gIx8gIh8fIx4hIx8gIx8gIx8gIx8g////qFhM1wAAABh0Uk5TAAAKGAMJl9prBJLX8m4B83D3+AEB1giNxISErAAAAAFiS0dEGexutYgAAAAJcEhZcwABOvYAATr2ATqxVzoAAACHSURBVDjL5ZJJDoAgDACtVAXccEP+/1KDook1tXflBJ1JupFlvzqQK4TrgaoAwouy0iYFwejKEgNUHZr2MMC0TagVETrdJ2Pnve5ojhiOxnV5VHkA5PhpOMfxZAwDy2P7bgxhdMhwUZBSSEWebeL7GNhBwURHPdNlLcKyvL2ve/U0h/Bhvn42thUKKJlqzs0AAAAldEVYdGRhdGU6Y3JlYXRlADIwMTgtMTEtMDlUMTA6MDM6MjIrMDE6MDC3+udLAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDE4LTExLTA5VDEwOjAzOjIyKzAxOjAwxqdf9wAAAEZ0RVh0c29mdHdhcmUASW1hZ2VNYWdpY2sgNi43LjgtOSAyMDE2LTA2LTE2IFExNiBodHRwOi8vd3d3LmltYWdlbWFnaWNrLm9yZ+a/NLYAAAAYdEVYdFRodW1iOjpEb2N1bWVudDo6UGFnZXMAMaf/uy8AAAAYdEVYdFRodW1iOjpJbWFnZTo6aGVpZ2h0ADUxMsDQUFEAAAAXdEVYdFRodW1iOjpJbWFnZTo6V2lkdGgANTEyHHwD3AAAABl0RVh0VGh1bWI6Ok1pbWV0eXBlAGltYWdlL3BuZz+yVk4AAAAXdEVYdFRodW1iOjpNVGltZQAxNTQxNzU0MjAyrrLbNQAAABN0RVh0VGh1bWI6OlNpemUAOS4zNEtCQo9MI1EAAAA8dEVYdFRodW1iOjpVUkkAZmlsZTovLy4vdXBsb2Fkcy81Ni91dm5kc1MyLzE2NzQvY2xvc2VfMTExMTUyLnBuZzVJD1EAAAAASUVORK5CYII=');
  }
}

