@import "~react-grid-layout/css/styles.css";
@import "~react-resizable/css/styles.css";

.react-grid-layout {
  //background: #eee;
  //margin-top: 10px;
  //width: 100%;
  overflow: hidden;
}
.react-grid-item {
  box-sizing: border-box;
  overflow: hidden;
}
.react-grid-item:not(.react-grid-placeholder) {
  //background: #ccc;
  //border: 1px solid black;
}
.react-grid-item.resizing {
  opacity: 0.9;
}
.react-grid-item.static {
  //background: #cce;
}
.react-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 24px;
}
.react-grid-item .minMax {
  font-size: 12px;
}
.react-grid-item .add {
  cursor: pointer;
}
.react-grid-dragHandleExample {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 8px;
  height: 8px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom-right-radius: 4px;
}

.react-resizable-handle {
  background-image: none;
}
